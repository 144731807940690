<template>
    <topic-container id="intro" image="title_image.jpg" is-large-border>

        <h1>Car Service Spreenhagen</h1>
        <text-box>
            Herzlich willkommen bei unserem Kfz-Meisterbetrieb! Bereits seit 1990 stehen wir unseren Kunden mit
            umfangreichem Fachwissen und unserer Erfahrung im Bereich der Fahrzeugwartung und -reparatur zur Verfügung.
            Dabei bieten wir Ihnen ein breites Leistungsspektrum an. Von der regelmäßigen Wartung bis hin zur
            umfassenden Reparatur aller Fahrzeugtypen. Egal ob PKW, Motorrad oder Nutzfahrzeug, bei uns sind Sie in
            besten Händen.
            <br><br>
            Wir führen Wartung und Reparatur an allen Fahrzeugen unterschiedlicher Hersteller durch und sind dabei
            stets Zuverlässig, um Ihnen eine bestmögliche Betreuung zu garantieren. Gerne beraten wir Sie
            ausführlich zu allen Fragen rund um Ihr Fahrzeug und finden gemeinsam mit Ihnen die beste Lösung. Sie
            erreichen uns sowohl telefonisch als auch per E-Mail oder besuchen Sie uns vor Ort in der Werkstatt.
            <br><br>
            Wir freuen uns darauf, Sie in unserer Werkstatt begrüßen zu dürfen!
        </text-box>

        <div class="navi-contact">
            <div v-if="$route.path === '/'">
                <h3>Leistungen</h3>
                <navigation-box/>
            </div>
            <div>
                <h3>Kontakt</h3>
                <contact-box/>
            </div>
        </div>

    </topic-container>
    <div class="container">
        <router-view/>
    </div>
    <the-footer/>
</template>

<script>
import "./assets/style.css"
import TopicContainer from "@/components/TopicContainer.vue";
import ContactBox from "@/components/ContactBox.vue";
import TextBox from "@/components/TextBox.vue";
import TheFooter from "@/components/TheFooter.vue";
import NavigationBox from "@/components/NavigationBox.vue";

export default {
    components: {NavigationBox, TheFooter, TextBox, ContactBox, TopicContainer}
}
</script>

<style>
.navi-contact {
    display: flex;
    flex-direction: row;
}

.navi-contact > div {
    margin: 2em 1em 0 1em;
}

@media screen and (max-width: 540px) {
    .navi-contact {
        flex-direction: column;
    }
}
</style>
