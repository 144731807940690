<template>
    <text-box>
        <div class="p-title">ÖFFNUNGSZEITEN</div>
        MO. – FR. | 8:30 – 18:00 UHR
        <br><br>
        <div class="p-title">TELEFON</div>
        033633 65377
        <br><br>
        <div class="p-title">E-MAIL</div>
        info@car-service-spreenhagen.de
        <br><br>
        <div class="p-title">WERKSTATT</div>
        Alte Dorfstrasse 8<br>
        15528 Spreenhagen
    </text-box>
</template>

<script>
import TextBox from "@/components/TextBox.vue";

export default {
    name: "ContactBox",
    components: {TextBox}
}
</script>

<style scoped>

</style>