<template>
    <div class="navi">
        <div class="element" @click="jumpTo('service')">Wartung</div>
        <div class="element" @click="jumpTo('repair')">Reparatur</div>
    </div>
</template>

<script>
export default {
    name: "NavigationBox",
    methods: {
        jumpTo(el) {
            const top = $("#" + el).offset().top + 10;
            $('html,body').animate({scrollTop: top}, 750);
        }
    }
}
</script>

<style scoped>
.navi {
    padding: 1em 0;
    border-radius: 1em;
    border-top: 5px #e63946 solid;
    border-bottom: 15px #1d3557 solid;
    background-color: #1d3557;
}

.navi .element {
    width: 14em;
    color: #fff;
    padding: 1em 2em;
    cursor: pointer;
    font-weight: bold;
    background-color: #1d3557;
    border-bottom: 1px #D7F1F3 solid;
}

.navi .element:first-child {
    border-top: 1px #D7F1F3 solid;
}

.navi .element:hover {
    background-color: #e63946;
}
</style>