<template>
    <div class="text-box">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TextBox"
}
</script>

<style scoped>
.text-box {
    padding: 2em;
    border-radius: 1em;
    background-color: rgba(215, 241, 243, 0.8);
    border-top: 5px #e63946 solid;
    border-bottom: 15px #1d3557 solid;
}
</style>