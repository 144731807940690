<template>
    <div class="footer white-link">
        <div>
            <router-link to="/">Startseite</router-link>
            <router-link to="/legal-notice">Impressum</router-link>
            <router-link to="/data-privacy">Datenschutzerklärung</router-link>
            <div>Webentwicklung: <a href="https://oceanbyte.de/" target="_blank">oceanbyte.de</a></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TheFooter"
}
</script>

<style scoped>
.footer {
    color: #fff;
    padding: 2em;
    background-color: #1d3557;

}

.footer > div {
    max-width: 40%;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 1500px) {
    .footer > div {
        max-width: 70%;
    }
}

@media screen and (max-width: 730px) {
    .footer > div {
        max-width: none;
        width: 100%;
    }
}

@media screen and (max-width: 540px) {
    .footer > div {
        flex-direction: column;
    }

    .footer > div > * {
        margin-bottom: 0.4em;
    }
}
</style>